import { apiService } from 'api/apiService';

export const getDailySalesApi = async (querystring: any) => {
  const response = await apiService.get(`/sales/servicecentre/?${querystring}`);
  return response;
};
export const getShipmentDetailsByIdApi = async (querystring: any) => {
  const response = await apiService.get(`/shipment/get?${querystring}`);
  return response;
};

export const getSalesExportDataApi = async (querystring: any) => {
  const response = await apiService.get(
    `/sales/servicecentre/export/?${querystring}`,
  );
  return response;
};
export const getDemurageReport = async(querystring:any)=>{
  const response = await apiService.get(`/demurage/report/?${querystring}`)
  return response
} 

export const getDemuragExportData = async(querystring:any)=>{
const response = await apiService.get(`/demurage/report/export?${querystring}`)
return response
}