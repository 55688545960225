import { Suspense } from 'react';
import { Layout } from 'components/layout/Layout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthenticationLayout } from 'pages/authentication/AuthenticationLayout';
import { store } from './store/store';
import { Pages } from 'Pages';
import { UtilContextProvider } from 'context/utils/UtilContext';
import { CaptureShipmentContextProvider } from 'context/shipment/CaptureShipmentContext';
import { loader } from 'assets';


function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<div>
        <img className="mx-auto my-auto" src={loader} alt="" />
      </div>}>
        <UtilContextProvider>
          <CaptureShipmentContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<AuthenticationLayout />} />
                <Route path="/forgot-password" element={<AuthenticationLayout />} />
                <Route path="/reset-password-otp" element={<AuthenticationLayout />} />
                <Route path="/reset-password" element={<AuthenticationLayout />} />
                <Route
                  path="/dashboard"
                  element={
                    <Layout> <Pages.Dashboard /> </Layout>
                  }
                />
                <Route
                  path='/notifications'
                  element={
                    <Layout><Pages.Notifications /></Layout>
                  }
                />
                <Route
                  path='/shipments/capture-shipments'
                  element={
                    <Layout><Pages.CaptureShipments /></Layout>
                  }
                />





                <Route
                  path='/shipments/capture-shipments/payment'
                  element={
                    <Layout><Pages.PaymentOptions /></Layout>
                  }
                />

                <Route
                  path='/shipments/capture-shipments/invoice'
                  element={
                    <Layout><Pages.InvoiceCaptureShipment /></Layout>
                  }
                />


                <Route
                  path="/shipments/capture-shipments/otp"
                  element={
                    <Layout>
                      {' '}
                      <Pages.VerificationOtp />{' '}
                    </Layout>
                  }
                />


                <Route
                  path="/shipments/daily-sales"
                  element={
                    <Layout>
                      <Pages.DailySales />
                    </Layout>
                  }
                />

                <Route
                  path="/shipments/daily-sales/shipment-invoice/"
                  element={
                    <Layout>
                      <Pages.shipmentInvoice />
                    </Layout>
                  }
                />
                <Route
                  path='/shipments/create-return-shipment'
                  element={
                    <Layout>
                      <Pages.CreateReturnShipment />
                    </Layout>
                  }
                />
                <Route
                  path="/shipments/cod-processing-order"
                  element={
                    <Layout>
                      {' '}
                      <Pages.CodProcessingOrder />{' '}
                    </Layout>
                  }
                />
                <Route
                  path="/shipments/bank-processing-order"
                  element={
                    <Layout>
                      {' '}
                      <Pages.BankProcessingOrder />
                    </Layout>
                  }
                />

                <Route
                  path="/shipments/transfer-management"
                  element={
                    <Layout>
                      {' '}
                      <Pages.TransferManagement />{' '}
                    </Layout>
                  }
                />

                <Route
                  path="/shipments/return-shipment"
                  element={
                    <Layout>
                      {' '}
                      <Pages.ReturnShipment />{' '}
                    </Layout>
                  }
                />
                <Route
                  path="/shipments/demurrage-processing-order"
                  element={
                    <Layout>
                      {' '}
                      <Pages.DemurrageProcessingOrder />{' '}
                    </Layout>
                  }
                />

                <Route
                  path='/processing-and-packaging/group-shipment-view'
                  element={
                    <Layout><Pages.GroupShipmentView /></Layout>
                  }
                />
                <Route
                  path="/processing-and-packaging/group-shipment/add-group-shipment"
                  element={
                    <Layout>
                      {' '}
                      <Pages.AddGroupShipmentView />{' '}
                    </Layout>
                  }
                />
                <Route
                  path="/processing-and-packaging/manifest"
                  element={
                    <Layout>
                      {' '}
                      <Pages.Manifest />{' '}
                    </Layout>
                  }
                />
                <Route
                  path="/processing-and-packaging/manifest/invoice"
                  element={
                    <Layout>
                      {' '}
                      <Pages.ManifestinvoiceDetail />{' '}
                    </Layout>
                  }
                />

                <Route
                  path="/processing-and-packaging/manifest/manifest-detail"
                  element={
                    <Layout>
                      {' '}
                      <Pages.ManifestDetail />{' '}
                    </Layout>
                  }
                />

                <Route
                  path="/processing-and-packaging/manifest/manifest-waybill-report"
                  element={
                    <Layout>
                      {' '}
                      <Pages.PandPWaybillReport />{' '}
                    </Layout>
                  }
                />

                <Route
                  path="/processing-and-packaging/manifest/generate-manifest"
                  element={
                    <Layout>
                      {' '}
                      <Pages.GenerateManifest />{' '}
                    </Layout>
                  }
                />

                {/* <Route
                  path="/international/inbound-shipment"
                  element={
                    <Layout>
                      <Pages.InboundShipment />
                    </Layout>
                  }
                /> */}
                <Route
                  path="/shipment-tracking/inbound-shipment"
                  element={
                    <Layout>
                      <Pages.InboundShipments />
                    </Layout>
                  }
                />
              </Routes>
            </BrowserRouter>
          </CaptureShipmentContextProvider>
        </UtilContextProvider>
      </Suspense>
    </Provider>
  );
}

export default App;
