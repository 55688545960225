import { CaptureShipmentContext } from "context/shipment/CaptureShipmentContext"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { ICaptureShipmentContextProps } from "utils/interfaces"




type Props = {
  className: string
  ref: any
}

const menuArray = [
  {title: "profile", url: "", navigate: true },
  {title: "notifications", url: "", navigate: true},
  {title: "change password", url: "", navigate: true},
  {title: "messages", url: "", navigate: true},
  {title: "logout", url: "", navigate: false},
]

export const MenuDropdown = ({className, ref}: Props) => {
  const navigate = useNavigate()

const {
    addSenderObject,
    updateReceiverData,    
    clearSpecificationState,
    clearMultiShipMentonCreateShipment, 
  } = useContext(CaptureShipmentContext) as ICaptureShipmentContextProps;


  return (
    <div ref={ref}
    className={`min-w-[19rem] hidden tb:block border border-col_ececec px-3 py-3 rounded-[1rem] bg-col_f8f8f8 ${className}`}
    >
      <p
        className='text-[0.7rem] text-col_807e7c font-bold mb-4 px-2'
      >ogenekaro.babatubde@giglogistics.com</p>
      <ul>
        {menuArray.map((item: any) => (
          <li
            onClick={() => {
              if (item.navigate) {
                // navigate()
                console.log("navigated")
              }
              if (item.title === 'logout') {
                // navigate()
                sessionStorage.clear();
                addSenderObject({});
                  updateReceiverData({});
                  clearSpecificationState()
                  clearMultiShipMentonCreateShipment("")
                navigate("/")
              }

              else {}
            }}
            className={`bg-transparent text-[0.7rem] text-col_807e7c hover:bg-col_e84e40 hover:text-col_fff hover:rounded-[6px] capitalize cursor-pointer mb-1 px-2 py-[0.5rem] `}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  )
}